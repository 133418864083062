import React from 'react';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../../helper';
import ButtonEditor from '../../LayoutComponents/ButtonEditor';

import * as styles from './styles.module.css';

class HeroLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';

    this.state = {
      align,
    };
  }

  render() {
    const headerAlignStyle = `heroHeader${this.state.align}`;
    const paragraphAlignStyle = `heroParagraph${this.state.align}`;
    const buttonAlignStyle = `heroBtnWrapper${this.state.align}`;
    const heroExceptionStyle = `heroException${this.props.themeData.typography.default.fontSize}`;
    const btnWrapper = this.props.layout === 'design3' ? styles.overlayBtnWrapper : styles[buttonAlignStyle];

    let style;
    if (this.props.layout === 'design4' || this.props.layout === 'design3') {
      let backgroundColor = { solid: '#ffffff' };
      let opacity = 1;

      if (
        this.props.section.styles.styles.bg &&
        this.props.section.styles.styles.bg.active &&
        this.props.section.styles.styles.bg.solid
      ) {
        backgroundColor = { solid: this.props.section.styles.styles.bg.solid };
        ({ opacity } = this.props.section.styles.styles.bg);
      }

      style = formColor(backgroundColor, false, opacity, undefined, this.props.themeData.colors);
    }

    let btn;
    const texts = [];
    this.props.section.data.forEach((item, index) => {
      if (item.active) {
        let type;
        let text;
        if (item.type.startsWith('HEADINGS') || item.type.startsWith('PARAGRAPH')) {
          if (item.type === 'HEADINGS/HEADING-ONE') {
            text = <h1>{HTMLParser(item.text)}</h1>;
            type = 'h1';
          } else if (item.type === 'PARAGRAPH/PARAGRAPH') {
            text = (
              <span style={{ color: this.props.themeData.colors[3] }} className={styles[heroExceptionStyle]}>
                {HTMLParser(item.text)}
              </span>
            );
            type = 'span';
          }
        }

        if (text) {
          const res = (
            <div
              key={`${this.props.section._id}_layout_${index}`}
              className={type === 'h1' ? styles[headerAlignStyle] : styles[paragraphAlignStyle]}
            >
              {text}
            </div>
          );
          texts.push(res);
        } else if (item.type.startsWith('BUTTON')) {
          btn = (
            <div
              key={`${this.props.section._id}_layout_${index}`}
              className={btnWrapper}
              style={this.props.layout === 'design3' ? style : undefined}
            >
              <ButtonEditor
                buttons={this.props.buttons}
                themeData={this.props.themeData}
                data={item.content}
                styleN={this.props.layout === 'design3' ? 'design3Pos' : ''}
                pagePathList={this.props.pagePathList}
                articlePathList={this.props.articlePathList}
                filePathList={this.props.filePathList}
                categoryPathList={this.props.categoryPathList}
              />
            </div>
          );
        }
      }

      return null;
    });

    let design4;
    if (this.props.layout === 'design4') {
      design4 = <div className={styles.design4} style={style} />;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1">
            {texts}
            {this.props.layout !== 'design3' && btn}
          </div>
          {this.props.layout === 'design3' && btn}
          {design4}
        </div>
      </div>
    );
  }
}

export default HeroLayout;
