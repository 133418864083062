const findCategoryById = (categories, id) => {
  for (let i = 0; i < categories.length; i += 1) {
    if (categories[i]._id === id) return categories[i];
    if (categories[i].children) {
      const result = findCategoryById(categories[i].children, id);
      if (result) return result;
    }
  }

  return null;
};

export default findCategoryById;
