// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "g_mQ";
export var warningBackgrounddark = "g_mR";
export var warningBackgroundcustom = "g_mS";
export var bottom = "g_gF";
export var modal = "g_mT";
export var container = "g_mV";
export var btnWrapper = "g_dZ";
export var elementWrapper = "g_mW";
export var titleWrapper = "g_mX";
export var contentWrapper = "g_mY";
export var btn = "g_mZ";
export var link = "g_m0";
export var decline = "g_m1 g_mZ";
export var editor = "g_m2";
export var row = "g_m3";