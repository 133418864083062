import React from 'react';
import HTMLParser from 'html-react-parser';
import { format } from 'date-fns/esm';
import Masonry from 'react-masonry-css';
import Cookies from 'universal-cookie';
import clsx from 'clsx';

import Lightbox from '../../../../Lightbox';
import NavHead from '../../../../NavHead';
import SectionMedia from '../../LayoutComponents/SectionMedia';
import LayoutContainer from '../../LayoutContainer';
import imageHelper from '../../../../Image/helper';
import Image from '../../../../Image';
import { constructLink, createPreviewPath, formColor, getEmbedUrl } from '../../../../../helper';
import CookieWarning from '../../../../CookieWarning';
import BackToTopButton from '../../../../TabletView/BackToTopButton';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';
import { InfoRowHeightContext } from '../../../../NavigationBar/InfoRowHeightContext';
import getNavHeight from '../../../../../helpers/getNavHeight';

import * as styles from './styles.module.css';

let IntersectionObserver;
if (typeof window !== 'undefined') {
  if (window.IntersectionObserver) IntersectionObserver = window.IntersectionObserver;
  else IntersectionObserver = require('intersection-observer'); // eslint-disable-line global-require
}

const cookies = new Cookies();

class Article extends React.Component {
  constructor(props) {
    super(props);

    cookies.get('cookiesAccepted');
    const { themeData, article, section, logo } = this.props.pageContext;

    const images = [];
    if (article) {
      article.data.forEach((c) => {
        if (c.type === 'GALLERY' && c.lightbox === true) {
          c.data.forEach((img) => {
            images.push(img);
          });
        }
      });
    }

    let previewSrc;
    let img;

    if (article && article.image.id) img = article.image.id;
    else if (article && article.articleLayout.heroImage.id) img = article.articleLayout.heroImage.id;
    else if (props.pageContext.seo && props.pageContext.seo.defaultPreview) img = props.pageContext.seo.defaultPreview;

    let imgData;
    if (img) {
      props.pageContext.images.some((i) => {
        let found = false;
        if (i._id.toString() === img || i.path === img) {
          imgData = i;
          found = true;
        }

        return found;
      });
    }

    if (imgData && imgData.path) previewSrc = createPreviewPath(imgData);

    const themeHeadingStyle = {
      fontFamily: themeData.typography.heading.name,
      lineHeight: themeData.typography.heading.lineHeight,
      letterSpacing: themeData.typography.heading.letterSpacing,
    };

    const color0 = {
      color: themeData.colors[0],
    };

    const nav = props.pageContext.navigation;
    const { overlay } = nav.styles;

    let logoHeight;
    if (logo && logo.active) logoHeight = logo.logoHeight !== undefined ? logo.logoHeight : 60;
    else {
      let lineHeight = 1.5;
      let size = 38;
      if (themeData && themeData.typography && themeData.typography.logo) {
        ({ lineHeight } = themeData.typography.logo);
        size =
          themeData.typography.logo.fontSize === 'Large'
            ? 50
            : themeData.typography.logo.fontSize === 'Small'
            ? 30
            : size;
      }

      logoHeight = size * lineHeight;
    }

    let linkHeight = 34;
    if (themeData && themeData.typography && themeData.typography.navigation) {
      linkHeight =
        themeData.typography.navigation.fontSize === 'Small'
          ? 30
          : themeData.typography.navigation.fontSize === 'Large'
          ? 40
          : linkHeight;
    }

    this.state = {
      section,
      article,
      photoIndex: 0,
      isOpen: false,
      images,
      themeHeadingStyle,
      color0,
      overlay,
      isScrolling: false,
      matches: null,
      previewSrc,
      logoHeight,
      linkHeight,
    };

    this.sentinel = React.createRef();
  }

  componentDidMount() {
    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

    if (this.props.pageContext.navigation.styles.fixed_top) {
      const elem = this.sentinel.current;
      if (elem) {
        const observer = new IntersectionObserver(this.handleScroll);
        observer.observe(elem);
      }
    }

    if (this.state.article.fbComments && window.FB) {
      window.FB.XFBML.parse();
    }
  }

  componentWillUnmount() {
    if (this.mediaQueryList) {
      this.mediaQueryList.removeListener(this.updateMatches);
    }
  }

  updateMatches = () => {
    this.setState({
      matches: this.mediaQueryList.matches,
    });
  };

  handleScroll = (entries) => {
    if (this.props.pageContext.navigation.styles.fixed_top) {
      this.setState({
        isScrolling: !entries[entries.length - 1].isIntersecting,
      });
    }
  };

  createText = (item, index, type, color) => {
    const cols = type !== 'quote' ? 'col-10 col-lg-8' : '';
    const id = `${this.state.article._id}_P_${index}_section`;
    let content;
    const classNames = [];

    if (type === 'subtitle') {
      content = <h2 style={{ color }}>{HTMLParser(item.text)}</h2>;
      classNames.push(styles.articleText);
    } else if (type === 'paragraph' || type === 'quote') {
      content = (
        <span style={{ color }} className={styles.text}>
          {HTMLParser(item.text)}
        </span>
      );
      if (type === 'paragraph') {
        classNames.push(styles.articleText);
      }
    }

    const text = <div>{content}</div>;

    return (
      <div key={id} className={clsx(cols, classNames)}>
        {text}
      </div>
    );
  };

  createImage = (item, index) => {
    if (!item) return null;

    return (
      <div className="col-10" key={`${this.state.article._id}_Image_${index}_section`}>
        <div className={styles.articleImageWrapper}>
          <CustomSectionMedia
            data={item}
            sizes="100vw"
            images={this.props.pageContext.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            categoryPathList={this.props.categoryPathList}
            colors={this.props.pageContext.themeData.colors}
          />
        </div>
      </div>
    );
  };

  createVideo = (item, index) => {
    let videoUrl;
    let video;
    if (item.videoID) videoUrl = getEmbedUrl(item);

    if (videoUrl) {
      video = (
        <div className="col-10 col-lg-8" key={`${this.state.article._id}_Article_${index}_Video`}>
          <iframe
            frameBorder="0"
            className={styles.videoIframeStyle}
            src={videoUrl}
            allowFullScreen
            aria-hidden="true"
          />
        </div>
      );
    }

    return video;
  };

  createGallery = (items, index, lightbox) => {
    const pictures = [];
    items.forEach((item, i) => {
      if (!item) return;

      const { crop } = this.state.article.data[index];
      const columnsCount = Number(this.state.article.data[index].columns) || 1;
      const sizes = [
        '(max-width: 576px) 100vw',
        `(min-width: 576px and max-width: 768px) ${columnsCount === 1 ? 100 : 50}vw`,
        `(min-width: 768px) ${100 / columnsCount}vw`,
      ].join(', ');

      pictures.push(
        <div key={`${this.state.article._id}_Image_${i}${index}_section`}>
          <div className={styles.articleImageWrapper} style={lightbox ? { cursor: 'pointer' } : undefined}>
            <SectionMedia
              mediaType={item.icon ? 'ICON' : 'IMAGE'}
              wrapperStyleName={crop ? 'imageWrapper100' : 'imageContent5'}
              imageStyleName={crop ? 'galleryImg' : 'imageFull'}
              sizes={sizes}
              elementIndex={Number(index)}
              galleryIndex={i}
              onImageClick={lightbox ? this.handleImageClick : undefined}
              src={item.CDNLink ? item.CDNLink : `${process.env.IMAGES_CDN}/${item.src}`}
              alt={item.alt}
              data={item}
              images={this.props.pageContext.images}
              pagePathList={this.props.pagePathList}
              articlePathList={this.props.articlePathList}
              filePathList={this.props.filePathList}
              categoryPathList={this.props.categoryPathList}
              colors={this.props.pageContext.themeData.colors}
            />
          </div>
        </div>,
      );
    });

    let gallery;
    const md = this.state.article.data[index].columns && Number(this.state.article.data[index].columns) === 1 ? 1 : 2;

    if (this.state.article.data[index].crop !== true) {
      const breakpointColumnsObj = {
        default: Number(this.state.article.data[index].columns),
        768: md,
        576: 1,
      };

      gallery = (
        <Masonry breakpointCols={breakpointColumnsObj} style={{ display: 'flex' }} className="" columnClassName="">
          {pictures.map((pic, i) => (
            <div key={`${this.state.article._id}_Gallery_Image${index}${i}`} className={styles.masonryImageWrapper}>
              {pic}
            </div>
          ))}
        </Masonry>
      );
    } else {
      gallery = [];
      const cols = `col-12 col-sm-${12 / md} col-md-${12 / Number(this.state.article.data[index].columns)}`;
      pictures.forEach((p, i) => {
        let key;
        if (i < pictures.length - 1) key = `${this.state.article._id}_Gallery_Image${index}${i}`;
        else key = `${this.state.article._id}_Gallery_AddBtnWrapper${index}`;

        const pic = (
          <div className={cols} key={key}>
            {p}
          </div>
        );

        return gallery.push(pic);
      });
    }

    return (
      <div key={`${this.state.article._id}_Gallery_${index}`} className="col-10">
        <div className="row">{gallery}</div>
      </div>
    );
  };

  createHeader = (clr) => {
    const navHeight = getNavHeight(
      this.context,
      this.state.logoHeight,
      this.state.linkHeight,
      this.props.pageContext.navigation.styles.layout,
      this.state.isScrolling && this.props.pageContext.navigation.styles.fixed_top,
    );

    const padding = {};
    padding.paddingTop = `${navHeight}px`;
    let color = { color: clr || '#000000' };

    if (this.state.article.articleLayout.heroActive === true) {
      const top = this.state.overlay === true ? navHeight + 120 : 120;
      padding.paddingBottom = '80px';
      padding.paddingTop = `${top}px`;
      color = clr ? color : { color: '#ffffff' };
    } else padding.marginBottom = 0;

    let pub;
    if (this.state.article.articleLayout.dateActive === true) {
      pub = format(new Date(this.state.article.pubDate), 'd.M.yyyy');
    }

    let tags;
    if (this.state.article.articleLayout.tagsActive === true) {
      tags = [];
      this.state.article.tags.forEach((t) => {
        const tag = <span style={clr ? { color: clr } : this.state.color0}>{t}</span>;
        tags.push(tag);
      });
    }

    let dateTag;
    if (pub !== undefined || tags !== undefined) {
      dateTag = (
        <div className={styles.dateTag} style={color}>
          {pub !== undefined && (
            <>
              <i className={`entypo icon-calendar ${styles.icon1}`} />
              <span>{pub}</span>
            </>
          )}
          {tags !== undefined && tags.length > 0 && (
            <>
              <i className={`entypo icon-mouse ${styles.icon2}`} />
              <span>
                {tags.map((tag, idx) => {
                  let text = ', ';
                  if (idx === tags.length - 1) {
                    text = '';
                  }

                  return (
                    <span key={`${this.state.article._id}_TagSpan_${this.state.article.tags[idx]}`}>
                      {tag}
                      {text}
                    </span>
                  );
                })}
              </span>
            </>
          )}
        </div>
      );
    }

    const size = this.state.article.articleLayout.heroImage.size || 'cover';
    const position = this.state.article.articleLayout.heroImage.position || 'center center';
    let id;
    if (this.state.article.articleLayout.heroActive === true) {
      if (
        this.state.article.articleLayout.articleImage &&
        (this.state.article.image.id || this.state.article.image.CDNLink)
      )
        ({ id } = this.state.article.image);
      else if (
        !this.state.article.articleLayout.articleImage &&
        (this.state.article.articleLayout.heroImage.id || this.state.article.articleLayout.heroImage.CDNLink)
      )
        ({ id } = this.state.article.articleLayout.heroImage);
    }

    let image;
    if (id !== undefined) {
      image = (
        <div className={styles.headerImageWrapper}>
          <Image
            id={id}
            sizes="100vw"
            size={size}
            position={position}
            alt="Background"
            imageStyleName="sectionBackgroundImageFull"
            images={this.props.pageContext.images}
          />
        </div>
      );
    }

    let overlay;
    if (
      this.state.article.articleLayout.overlay &&
      (this.state.article.articleLayout.overlay.color || this.state.article.articleLayout.overlay.opacity >= 0)
    ) {
      const oColor = this.state.article.articleLayout.overlay.color || '#000';
      const oOpacity =
        this.state.article.articleLayout.overlay.opacity >= 0 ? this.state.article.articleLayout.overlay.opacity : 0.7;

      overlay = formColor({ solid: oColor }, undefined, oOpacity, undefined, this.props.pageContext.themeData.colors);
    }

    const hColor = clr ? { color: clr } : color;
    const text = (
      <div className={`row ${styles.articleRow}`}>
        <div className={this.state.article.articleLayout.heroActive === true ? 'col-10' : 'col-10 col-lg-8'}>
          {dateTag}
          <div className={styles.headerText}>
            <h1 style={hColor}>{HTMLParser(this.state.article.title)}</h1>
          </div>
          {this.state.article.articleLayout.excerptActive === true && (
            <div className={styles.headerText}>
              <span
                className={styles[`Subtitle${this.props.pageContext.themeData.typography.heading.fontSize}`]}
                style={{ ...this.state.themeHeadingStyle, fontWeight: 'normal', ...hColor }}
              >
                {HTMLParser(this.state.article.excerpt)}
              </span>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <div
        key={`${this.state.article._id}_${this.state.article._id}_Header`}
        className={`${styles.header} ${
          this.state.article.articleLayout.heroActive === true ? ` ${styles.center}` : ''
        }`}
        style={padding}
      >
        {image}
        {this.state.article.articleLayout.heroActive === true && (
          <div className={styles[`headerGradient${overlay ? 'Overlay' : ''}`]} style={overlay} />
        )}
        <div className={styles[`headerContentWrapper${!this.state.article.articleLayout.heroActive ? 'Alt' : ''}`]}>
          <div className={`container ${styles.contentWrapper}`}>{text}</div>
        </div>
      </div>
    );
  };

  createQuote = (item, index, color) => {
    return (
      <div className={`col-8 ${styles.quoteWrapper}`} key={`${this.state.article._id}_Article_${index}_Quote`}>
        <div className={styles.quoteBar} style={{ backgroundColor: this.state.color0.color }} />
        <div className={styles.quoteText}>
          <div style={{ height: '10px' }} />
          {this.createText(item, index, 'quote', color)}
          <div style={{ height: '10px' }} />
        </div>
      </div>
    );
  };

  createAuthorBox = (auth, isSharing, clr) => {
    const author = this.state.section.styles.authors.find((a) => a._id === auth);

    let box;
    if (author !== undefined) {
      const links = [];
      if (author.links.length > 0) {
        author.links.forEach((l, i) => {
          if (l.active === true && l.image.icon !== null) {
            const { link } = constructLink(
              l.image.linkObj,
              this.props.pageContext.pagePathList,
              this.props.pageContext.articlePathList,
              this.props.pageContext.categoryPathList,
            );

            let tab;
            if (l.image.linkObj) tab = l.image.linkObj.openLinkInNewTab;
            const target = tab ? '_blank' : '_self';
            const rel = tab ? 'noopener noreferrer' : '';
            const newLink = (
              <a
                key={`${this.state.article._id}_ArticleMother_Owner_Link_${i}`}
                href={link}
                target={target}
                rel={rel}
                draggable="false"
              >
                <i
                  className={`entypo ${l.image.icon.class}`}
                  aria-hidden="true"
                  style={{
                    fontSize: l.image.icon.size,
                    color: l.image.icon.color,
                    marginRight: '4px',
                  }}
                />
              </a>
            );

            links.push(newLink);
          }

          return null;
        });
      }

      const color = clr ? { color: clr } : undefined;
      box = (
        <div key={`${this.state.article._id}_Article_AuthorBox`} className={styles.authorBox}>
          <div className={styles[isSharing ? 'bottomSeparator' : 'line']} />
          <div className={`row ${styles.authorRow}`}>
            <div className="col-10 col-sm-3">
              {author.image !== '' && (
                <div className={styles.authorImage}>
                  <SectionMedia
                    mediaType={author.image.icon ? 'ICON' : 'IMAGE'}
                    wrapperStyleName="imageContent3"
                    imageStyleName=""
                    iconStyleName=""
                    sizes="25vw"
                    src={author.image.CDNLink ? author.image.CDNLink : `${process.env.IMAGES_CDN}/${author.image.src}`}
                    alt={author.image.alt}
                    data={author.image}
                    images={this.props.pageContext.images}
                    pagePathList={this.props.pagePathList}
                    articlePathList={this.props.articlePathList}
                    filePathList={this.props.filePathList}
                    categoryPathList={this.props.categoryPathList}
                    colors={this.props.pageContext.themeData.colors}
                  />
                </div>
              )}
            </div>
            <div className="col-10 col-sm-5">
              {author.name !== '' && (
                <div>
                  <h4 className={styles.authorText} style={color}>
                    {author.name}
                  </h4>
                </div>
              )}
              {author.desc !== '' && (
                <div>
                  <span className={styles.authorText}>{HTMLParser(author.desc)}</span>
                </div>
              )}
              {links.length > 0 && <div className={styles.linksWrapper}>{links}</div>}
            </div>
          </div>
        </div>
      );
    }

    return box;
  };

  handleImageClick = (event) => {
    const photoIndex = this.state.images.findIndex((image) => image.id === event.target.dataset.imageid);

    if (photoIndex > -1) {
      this.setState({
        photoIndex,
        isOpen: true,
      });
    }
  };

  handleCloseClick = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleMoveNextRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
    });
  };

  handleMovePrevRequest = () => {
    this.setState({
      photoIndex: (this.state.photoIndex + (this.state.images.length - 1)) % this.state.images.length,
    });
  };

  documentReady = (callbackFunction) => {
    if (typeof document !== 'undefined') {
      if (document.readyState !== 'loading') {
        callbackFunction();
      } else {
        document.addEventListener('DOMContentLoaded', callbackFunction);
      }
    }
  };

  createSharing = (sharing, href, clr) => {
    let style;
    const { colors } = this.props.pageContext.themeData;
    const type =
      this.state.article.sharingType && this.state.article.sharingType !== 'default'
        ? this.state.article.sharingType
        : sharing.type;
    switch (type) {
      case 'black':
        style = { color: '#000' };
        break;
      case 'white':
        style = { color: '#FFF' };
        break;
      case 'theme':
        style = { color: formColor({ solid: 'COLOR_0' }, false, 1, null, colors).backgroundColor };
        break;
      default:
        style = {
          color: formColor({ solid: sharing.shareStyle.color }, false, sharing.shareStyle.opacity, null, colors)
            .backgroundColor,
        };
    }

    const color = clr ? { color: clr } : {};
    return (
      <div key={`${this.state.article._id}_Article_Sharing`} className={clsx('col-8', styles.sharing)}>
        {sharing.shareHeading.active && sharing.shareHeading.text && (
          <div className={styles.shareText}>
            <h6 style={{ ...color, ...{ marginBlockStart: 0, marginBlockEnd: 0 } }}>
              {HTMLParser(sharing.shareHeading.text)}
            </h6>
          </div>
        )}
        <div>
          {['facebook', 'twitter', 'linkedin', 'whatsapp'].map((s) => {
            let icon;
            if (sharing[s]) {
              let url;
              switch (s) {
                case 'facebook':
                  url = `https://www.facebook.com/share.php?u=${href}`;
                  break;
                case 'twitter':
                  url = `https://twitter.com/intent/tweet?url=${href}`;
                  break;
                case 'linkedin':
                  url = `https://www.linkedin.com/sharing/share-offsite/?url=${href}`;
                  break;
                default:
                  url = `whatsapp://send?text=${href}`;
              }
              icon = (
                <a
                  key={s}
                  data-action={s === 'whatsapp' ? 'share/whatsapp/share' : undefined}
                  target="popup"
                  href={url}
                  onClick={
                    typeof window !== 'undefined'
                      ? (e) => {
                          e.preventDefault();
                          window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                        }
                      : undefined
                  }
                >
                  <i
                    className={clsx('entypo', `icon-${s === 'whatsapp' ? s : `${s}-squared`}`, styles.icon)}
                    style={style}
                    aria-hidden="true"
                  />
                </a>
              );
            }
            return icon;
          })}
        </div>
      </div>
    );
  };

  render() {
    const { pageContext } = this.props;
    const { fbComments, share, articleLayout } = this.state.article;
    const href = `https://${pageContext.idn}${this.state.article.link.target}`;
    const fontColorData = articleLayout?.fontColor?.color ? articleLayout.fontColor : { color: '#000000', opacity: 1 };
    const headerColorData = articleLayout?.headerColor ? articleLayout.headerColor : { color: '#000000', opacity: 1 };
    const color = formColor(
      { solid: fontColorData.color },
      false,
      fontColorData.opacity,
      undefined,
      pageContext.themeData.colors,
    ).backgroundColor;
    const hColor = formColor(
      { solid: headerColorData.color },
      false,
      headerColorData.opacity,
      undefined,
      pageContext.themeData.colors,
    ).backgroundColor;

    let ready = false;
    if (typeof document !== 'undefined') {
      this.documentReady(() => {
        ready = true;
      });
    }

    let header;
    let article;
    let isSharing = false;
    let authorActive = false;

    if (this.state.section !== undefined && this.state.article !== undefined) {
      article = [];
      header = this.createHeader(articleLayout?.headerColor && hColor);

      this.state.article.data.forEach((item, index) => {
        if (index - 1 >= 0 && this.state.article.data[index - 1].type !== 'HEADINGS/HEADING-TWO') {
          const add = <div key={`${this.state.article._id}_article_separator_${index}`} className={styles.separator} />;

          article.push(add);
        }

        let elem;
        if (item.type === 'PARAGRAPH/PARAGRAPH') {
          elem = this.createText(item, index, 'paragraph', color);
        } else if (item.type === 'HEADINGS/HEADING-TWO') {
          elem = this.createText(item, index, 'subtitle', color);
        } else if (item.type === 'PARAGRAPH/QUOTE') {
          elem = this.createQuote(item, index, color);
        } else if (item.type === 'IMAGE') {
          elem = this.createImage(item.content, index);
        } else if (item.type === 'VIDEO') {
          elem = this.createVideo(item.content, index);
        } else if (item.type === 'GALLERY') {
          elem = this.createGallery(item.data, index, item.lightbox);
        } else {
          if (pageContext.sharing && pageContext.sharing.active && share) {
            isSharing = true;
            article.push(this.createSharing(pageContext.sharing, href, articleLayout?.fontColor && color));
          }

          if (item.active === true) {
            elem = this.createAuthorBox(item.author, isSharing, articleLayout?.fontColor && color);
            authorActive = true;
          }
        }

        article.push(elem);
      });
    }

    const backgroundColorData = this.state.article.articleLayout.backgroundColor || { solid: '#ffffff', opacity: 1 };

    const { backgroundColor } = formColor(
      backgroundColorData,
      false,
      backgroundColorData.opacity,
      undefined,
      pageContext.themeData.colors,
    );

    return (
      <>
        <div ref={this.sentinel} />
        <div id="modal-root" />
        <NavHead
          isScrolling={this.state.isScrolling}
          pageContext={pageContext}
          previewSrc={this.state.previewSrc}
          metaTitle={this.state.article ? this.state.article.metaTitle : undefined}
          metaDescription={this.state.article ? this.state.article.metaDescription : undefined}
          title={pageContext.sanitizedTitle}
          desc={pageContext.sanitizedExcerpt}
          comments={fbComments ? pageContext.comments : null}
          href={href}
        />
        <div className="longarticle" style={{ color, backgroundColor }}>
          {header}
          <div className="container">
            {pageContext.backToTopButton && pageContext.backToTopButton.active && (
              <BackToTopButton colour={pageContext.backToTopButton.colour} />
            )}
            {this.state.images.length > 0 && (
              <Lightbox
                mainSrc={imageHelper.getBestQuality(this.state.images[this.state.photoIndex].id, pageContext.images)}
                nextSrc={imageHelper.getBestQuality(
                  this.state.images[(this.state.photoIndex + 1) % this.state.images.length].id,
                  pageContext.images,
                )}
                prevSrc={imageHelper.getBestQuality(
                  this.state.images[(this.state.photoIndex + (this.state.images.length - 1)) % this.state.images.length]
                    .id,
                  pageContext.images,
                )}
                onCloseRequest={this.handleCloseClick}
                onMovePrevRequest={this.handleMovePrevRequest}
                onMoveNextRequest={this.handleMoveNextRequest}
                visible={this.state.isOpen}
              />
            )}
            <div className={`row ${styles.articleRow}`}>
              {article}
              {fbComments && pageContext.comments && (
                <>
                  <div className={styles[authorActive || !isSharing ? 'line' : 'bottomSeparator']} />
                  <div className={`col-10 col-sm-8 ${styles.comments}`}>
                    {pageContext.comments.text && (
                      <div>
                        <h4 style={articleLayout?.fontColor ? { color } : undefined}>{pageContext.comments.text}</h4>
                      </div>
                    )}
                    <div
                      className="fb-comments"
                      data-lazy="true"
                      data-numposts={pageContext.comments.numComments}
                      data-width="100%"
                      data-order-by={pageContext.comments.sortBy}
                      data-href={href}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {pageContext.footer && (
          <LayoutContainer
            pageContext={pageContext}
            key={`Layout_${pageContext.footer._id}`}
            section={pageContext.footer}
            matches={this.state.matches}
          />
        )}
        {pageContext?.cookie?.active === true &&
          cookies &&
          cookies.cookies &&
          cookies.cookies.cookiesAccepted === undefined &&
          ready === true && (
            <CookieWarning
              themeData={pageContext.themeData}
              cookie={pageContext.cookie}
              pagePathList={pageContext.pagePathList}
              articlePathList={pageContext.articlePathList}
              categoryPathList={pageContext.categoryPathList}
              buttons={pageContext.buttons}
              images={pageContext.images}
              trackers={pageContext.seo.trackers}
              id={pageContext.page._id}
            />
          )}
      </>
    );
  }
}

Article.contextType = InfoRowHeightContext;

export default Article;
